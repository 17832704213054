/* body {
  overflow-x: hidden;
} */
.footer-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.contacts-header {
  display: flex;
  align-items: center;
}
.line-image {
  position: relative;
  top: 15px;
  right: 8.5rem;
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  max-width: 100%;
}

.image {
  max-width: cover;
  width: 100%;

  height: auto;
  margin-bottom: -0.2rem;
}

.footer-info {
  position: absolute;
  bottom: 0;
  left: 10rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #2d3d4b;
  padding: 20px;
}

.overlay-text {
  font-size: var(--h1-font-size);
  color: white;
  position: absolute;
  top: 25%;
  left: 74%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.overlay-text::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: rgba(101, 101, 101, 0.8);
  left: -0.9rem;
  transform: translateX(-50%);
}
.footer-info strong {
  font-size: 2rem;
  margin-bottom: 10px;
  margin-left: 2rem;
}
.footer-info p {
  font-size: var(--h3-font-size);
  margin-bottom: 0;
  padding: 2rem;
  font-weight: 500;
  width: 80%;
}

.instagram-link {
  color: #2d3d4b;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.btn__contact-footer {
  border: none;
  background: none;
  display: flex;
  color: black;
  width: 100%;
  padding: 18px 0 15px 76px;
  position: relative; /* Adicionando position: relative; */
  right: -30rem;
  margin-right: 5rem;
  top: -0.1rem;
  border: none;
  border-radius: 8px;
  background-color: white;
  font-size: var(--h3-font-size);
  font-family: "AvenirLT Std-Book", sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.1s;

  box-shadow: rgba(0, 0, 0, 0.867) 1.95px 1.95px 2.6px;
  transition: transform 0.3s, background-color 0.1s;
}

.btn__contact-footer:hover {
  transform: scale(1.05);
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
}

.btn__contact-text-footer {
  margin-right: 2px;
}

/*-------------------------------------------BREAKPOINTS-----------------------------------------*/

/*For large devices*/
@media screen and (max-width: 1700px) {
  .btn__contact-footer {
    font-size: 1rem;
    width: 274px;

    margin-left: -13rem;
    margin-top: 7rem;
  }

  .nav__icon-footer {
    font-size: 1rem;
    height: 19px;
  }
}
@media screen and (max-width: 1600px) {
  .btn__contact-footer {
    font-size: 1rem;
    width: 274px;

    margin-left: -28rem;
    margin-top: 7rem;
  }

  .nav__icon-footer {
    font-size: 1rem;
    height: 19px;
  }
}
@media screen and (max-width: 1400px) {
  .btn__contact-footer {
    font-size: 1rem;
    width: 274px;

    margin-left: -28rem;
    margin-top: 7rem;
  }

  .nav__icon-footer {
    font-size: 1rem;
    height: 19px;
  }
}

@media screen and (max-width: 1024px) {
  .btn__contact-footer {
    font-size: 1rem;
    width: 274px;

    margin-left: -40rem;
    margin-top: 12rem;
  }

  .footer-info {
    font-size: 0.5rem;
    left: -1rem;
  }
  .footer-info p {
    left: -1rem;
    padding: 1rem;
  }
  .footer-info strong {
    left: -19rem;
  }
  .nav__icon-footer {
    font-size: 1rem;
    height: 19px;
  }
}
@media screen and (max-width: 992px) {
  .btn__contact-footer {
    font-size: 1rem;
    width: 274px;

    margin-left: -28rem;
    margin-top: 7rem;
  }
  .image-wrapper {
    margin-bottom: -0.5rem;
  }
  .line-image {
    display: none;
  }
  .btn__contact-footer {
    margin-top: 3rem;
  }
  .btn__contact-img-instagram {
    width: 20px;
  }
  .overlay-text {
    font-size: var(--h1-font-size);
    top: 20%;
  }

  .footer-info {
    bottom: 0.5rem;
    left: 2rem;
  }

  .footer-info strong {
    font-size: var(--h1-font-size);
    margin-bottom: 1px;
    margin-left: 0.5rem;
  }

  .footer-info p {
    font-size: var(--small-font-size);
    margin-bottom: 1rem;
    padding: 0.5rem;
  }

  .btn__contact-footer:hover {
    transform: scale(1.02);
  }
}

@media screen and (max-width: 820px) {
  .footer-info {
    top: 30rem;
    left: 2rem;
  }
}
@media screen and (max-width: 800px) {
  .footer-info {
    top: 30rem;
    left: 2rem;
  }
}

/*For medium devices*/

@media screen and (max-width: 768px) {
  .btn-footer-whatsapp {
    margin-bottom: -14px;
  }
  .overlay-text {
    font-size: var(--normal-font-size);
    top: 20%;
  }

  .footer-info {
    bottom: 0.3rem;
    left: 1rem;
  }

  .footer-info strong {
    font-size: var(--normal-font-size);
    margin-left: 0.3rem;
  }

  .footer-info p {
    font-size: var(--small-font-size);
    margin-bottom: 0.8rem;
    padding: 0.3rem;
  }

  .btn__contact-img-instagram {
    width: 18px;
  }

  .btn__contact-text-footer {
    margin-left: 3px;
  }
}

/* For small devices*/
@media screen and (max-width: 576px) {
  .btn-footer-whatsapp {
    margin-bottom: 40px;
  }
  .overlay-text {
    font-size: var(--h3-font-size);
    top: 25%;
  }
  .btn__contact-img-instagram {
    width: 14px;
  }
  .footer-info {
    top: 15rem;
    left: -0.2rem;
  }

  .footer-info strong {
    font-size: var(--small-font-size);
    margin-left: 0.1rem;
  }
  .btn__contact-footer {
    border: none;
    background: none;
    display: flex;
    color: black;
    width: fit-content;
    padding: 15px 20px 10px 20px;
    position: relative;
    right: -20rem;
    top: 1rem;
    border: none;

    background-color: white;
    font-size: var(--smaller-font-size);
    font-family: "AvenirLT Std-Book", sans-serif;
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.3s, background-color 0.1s;
    box-shadow: rgba(0, 0, 0, 0.867) 1.95px 1.95px 2.6px;
    transition: transform 0.3s, background-color 0.1s;
  }

  .nav__icon-footer {
    font-size: var(--small-font-size);
    margin-top: -3px;
  }

  .footer-info p {
    font-size: 0.725rem;
    margin-bottom: 0.1rem;
    padding: 0.1rem;
  }
}

@media screen and (max-width: 430px) {
  .footer-info {
    top: 15rem;
    left: -0.8rem;
  }

  .footer-info strong {
    font-size: var(--small-font-size);
    margin-left: 0.1rem;
  }
  .btn__contact-footer {
    width: fit-content;
    padding: 15px 20px 10px 20px;
    position: relative;
    right: -24rem;
    top: 0.5rem;
    border: none;

    font-size: var(--smaller-font-size);
  }

  .nav__icon-footer {
    font-size: var(--small-font-size);
    margin-top: -3px;
  }

  .footer-info p {
    font-size: 0.725rem;
    margin-bottom: 0.1rem;
    padding: 0.1rem;
  }
}

@media screen and (max-width: 414px) {
  .btn-footer-whatsapp {
    margin-bottom: 24px;
  }
  .footer-info {
    top: 15rem;
    left: -0.5rem;
  }

  .footer-info strong {
    font-size: var(--small-font-size);
    margin-left: 0.1rem;
  }
  .btn__contact-footer {
    border: none;
    background: none;
    display: flex;
    color: black;
    width: fit-content;
    padding: 15px 20px 10px 20px;
    position: relative;
    right: -24rem;
    top: 0.5rem;
    border: none;

    background-color: white;
    font-size: var(--smaller-font-size);
    font-family: "AvenirLT Std-Book", sans-serif;
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.3s, background-color 0.1s;
    box-shadow: rgba(0, 0, 0, 0.867) 1.95px 1.95px 2.6px;
    transition: transform 0.3s, background-color 0.1s;
  }

  .nav__icon-footer {
    font-size: var(--small-font-size);
    margin-top: -3px;
  }

  .footer-info p {
    font-size: 0.725rem;
    margin-bottom: 0.1rem;
    padding: 0.1rem;
  }
}

@media screen and (max-width: 412px) {
  .btn__contact-footer {
    width: fit-content;
    padding: 15px 20px 10px 20px;
    position: relative;
    right: -24rem;
    top: 0.5rem;

    font-size: var(--smaller-font-size);
  }
}
@media screen and (max-width: 390px) {
  .footer-info {
    top: 13.4rem;
    left: -0.2rem;
  }

  .footer-info strong {
    font-size: var(--small-font-size);
    margin-left: 0.1rem;
  }
  .btn__contact-footer {
    width: fit-content;
    padding: 10px 12px 6px 10px;
    position: relative;
    left: 23rem;
    top: 1rem;
  }

  .nav__icon-footer {
    font-size: var(--small-font-size);
    margin-left: 4px;
  }

  .footer-info p {
    font-size: 0.725rem;
    margin-bottom: 0.2rem;
    padding: 0.1rem;
  }
}
@media screen and (max-width: 393px) {
  .footer-info {
    top: 13.4rem;
    left: -0.2rem;
  }

  .footer-info strong {
    font-size: var(--small-font-size);
    margin-left: 0.1rem;
  }
  .btn__contact-footer {
    width: fit-content;
    padding: 10px 12px 6px 10px;
    position: relative;
    right: -33rem;
    top: 1rem;
  }

  .nav__icon-footer {
    font-size: var(--small-font-size);
    margin-left: 4px;
  }

  .footer-info p {
    font-size: 0.725rem;
    margin-bottom: 0.2rem;
    padding: 0.1rem;
  }
}

@media screen and (max-width: 375px) {
  .footer-info {
    top: 12.8rem;
    left: -0.5rem;
  }

  .btn__contact-footer {
    width: fit-content;
    padding: 10px 12px 6px 10px;
    position: relative;
    right: -20rem;
    top: 1.2rem;
  }
}

@media screen and (max-width: 360px) {
  .footer-info {
    top: 12rem;
    left: -0.5rem;
  }

  .btn__contact-footer {
    width: fit-content;
    padding: 10px 12px 6px 10px;
    position: relative;
    left: 20rem;
    top: 1.2rem;
  }
}

@media screen and (max-width: 350px) {
  .overlay-text {
    font-size: var(--small-font-size);
    top: 30%;
  }
  .btn-footer-whatsapp {
    margin-bottom: 1px;
  }

  .footer-info {
    left: -0.8rem;
    top: 10.3rem;
  }
  .footer-info strong {
    font-size: 0.7rem;
    margin-left: 0.1rem;
  }
  .btn__contact-footer {
    display: none;
  }

  .footer-info p {
    margin: 0;
    font-size: 0.7rem;
    margin-bottom: 0.3rem;
    padding: 0;
  }
}
