/* body {
  overflow-x: hidden;
} */
.home__container {
  row-gap: 7rem;
  font-family: "AvenirLT Std-Roman", sans-serif;
}

.home__content {
  grid-template-columns: repeat(2, 1fr);
  padding-top: 10rem;
  column-gap: 18rem;
  padding-left: 19rem;

  align-items: start;
}

/*--------------STYLES BUTTON----------------*/

.btn__contact__home {
  margin-top: var(--mb-3);
  border: none;
  background: none;
  color: black;
  width: 60%;
  padding: 10px;
  border: none;
  border-radius: 2px;
  background-color: white;
  font-size: var(--normal-font-size);
  font-family: "AvenirLT Std-Book", sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.1s;
}

.btn__contact__home:hover {
  transform: scale(1.05);
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
}
/*----------------------------------------------------*/

.home__data {
  width: 80%;
}
.home__title {
  font-size: 1.8rem;
  margin-bottom: var(--mb-3);
  color: #ffffff;

  position: relative;
  padding-left: 8rem;
}

.home__title::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 110%;
  background-color: rgba(0, 0, 0, 0.534);

  left: 7rem;
  top: -1.2rem;
}

.home__img {
  background: url(../../assets/Imagens/Imagem\ Principal.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  order: 1;
  justify-self: center;
  margin-top: -3rem;
  margin-left: -40rem;
  width: 700px;
  height: 400px;
}

/*-------------------------------------------BREAKPOINTS-----------------------------------------*/

/*For large devices*/
@media screen and (max-width: 1700px) {
  .home__title {
    font-size: 1.8rem;
    margin-bottom: var(--mb-3);
    color: #ffffff;

    position: relative;
    padding-left: 4rem;
  }

  .home__title::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 110%;
    background-color: rgba(0, 0, 0, 0.534);

    left: 3rem;
    top: -1.2rem;
  }
  .home__content {
    padding-left: 3rem;
    margin-top: -4rem;
  }
  .home__title {
    font-size: 1.8rem;
    margin-bottom: -3rem;
    color: #ffffff;
    width: 100%;
    position: relative;
    padding-left: -2rem;
    padding-top: 1rem;
  }
  .home__title::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 90%;
    background-color: rgba(0, 0, 0, 0.534);

    left: 3rem;
    top: 2rem;
  }

  .home__img {
    width: 610px;
    height: 360px;
    margin-left: -30rem;
    margin-top: 2.5rem;
  }
}

@media screen and (max-width: 1400px) {
  .home__title {
    font-size: 1.8rem;
    margin-bottom: var(--mb-3);
    color: #ffffff;

    position: relative;
    padding-left: 4rem;
  }

  .home__title::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 110%;
    background-color: rgba(0, 0, 0, 0.534);

    left: 3rem;
    top: -1.2rem;
  }
  .home__content {
    padding-left: 3rem;
    margin-top: -4rem;
  }
  .home__title {
    font-size: 1.8rem;
    margin-bottom: -3rem;
    color: #ffffff;
    width: 100%;
    position: relative;
    padding-left: -2rem;
    padding-top: 1rem;
  }
  .home__title::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 90%;
    background-color: rgba(0, 0, 0, 0.534);

    left: 3rem;
    top: 2rem;
  }

  .home__img {
    width: 610px;
    height: 360px;
    margin-left: -30rem;
    margin-top: 2.5rem;
  }
}

@media screen and (max-width: 1280px) {
  .home__title {
    font-size: 1.8rem;
    margin-bottom: var(--mb-3);
    color: #ffffff;

    position: relative;
    padding-left: 4rem;
  }

  .home__title::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 110%;
    background-color: rgba(0, 0, 0, 0.534);

    left: 3rem;
    top: -1.2rem;
  }
  .home__content {
    padding-left: 3rem;
    margin-top: -4rem;
  }
  .home__title {
    font-size: 1.8rem;
    margin-bottom: -3rem;
    color: #ffffff;
    width: 100%;
    position: relative;
    padding-left: -2rem;
    padding-top: 1rem;
  }
  .home__title::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 90%;
    background-color: rgba(0, 0, 0, 0.534);

    left: 3rem;
    top: 2rem;
  }

  .home__img {
    width: 510px;
    height: 260px;
    margin-left: -20rem;
    margin-top: 4.5rem;
  }
}
/* 
@media screen and (max-width: 1100px) {
  .home__title {
    font-size: 1.8rem;
    margin-bottom: var(--mb-3);
    color: #ffffff;

    position: relative;
    padding-left: 4rem;
  }

  .home__title::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 110%;
    background-color: rgba(0, 0, 0, 0.534);

    left: 3rem;
    top: -1.2rem;
  }
  .home__content {
    padding-left: 3rem;
    margin-top: -4rem;
  }
  .home__title {
    font-size: 1.5rem;
    margin-bottom: -3rem;
    color: #ffffff;
    width: 300px;
    position: relative;
    padding-left: -2rem;
    padding-top: 1rem;
  }
  .home__title::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 90%;
    background-color: rgba(0, 0, 0, 0.534);

    left: 3rem;
    top: 2rem;
  }

  .home__img {
    width: 510px;
    height: 260px;
    margin-left: -15em;
    margin-top: 4.5rem;
  }
} */

@media screen and (max-width: 1100px) {
  .home__title {
    font-size: 1.8rem;
    margin-bottom: var(--mb-3);
    color: #ffffff;

    position: relative;
    padding-left: 4rem;
  }

  .home__title::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 110%;
    background-color: rgba(0, 0, 0, 0.534);

    left: 3rem;
    top: -1.2rem;
  }
  .home__content {
    padding-left: 3rem;
    margin-top: -4rem;
  }
  .home__title {
    font-size: 1.5rem;
    margin-bottom: -3rem;
    color: #ffffff;
    width: 300px;
    position: relative;
    padding-left: -2rem;
    padding-top: 1rem;
  }
  .home__title::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 90%;
    background-color: rgba(0, 0, 0, 0.534);

    left: 3rem;
    top: 2rem;
  }

  .home__img {
    width: 510px;
    height: 260px;
    margin-left: -15em;
    margin-top: 4.5rem;
  }
}

@media screen and (min-width: 885px) and (max-width: 992px) {
  .home__content {
    grid-template-columns: 220px repeat(2, 1fr);
    column-gap: 0.25rem;
    position: absolute;

    left: 24rem;

    top: 17rem;
    padding-top: 6rem;
  }

  .home__title {
    font-size: 1.2rem;
    right: 24rem;
    bottom: 2rem;
    width: 290px;
  }
  .home__img {
    width: 360px;
    height: 200px;
    margin-top: 3rem;
    margin-left: -8rem;
  }
  .btn__contact__home {
    width: 100%;
  }
  .home__title::before {
    height: 110%;

    top: -0.5rem;
  }
}
/* @media screen and (max-width: 992px) {
  .home__content {
    grid-template-columns: 220px repeat(2, 1fr);
    column-gap: 0.25rem;
    position: absolute;
    margin-top: -14rem;
    left: 10rem;

    top: 12rem;
    padding-top: 6rem;
  }

  .home__title {
    font-size: 1.5rem;
    right: 10rem;
    bottom: 2.8rem;
  }
  .home__img {
    width: 360px;
    height: 200px;
  }
} */
@media screen and (max-width: 884px) {
  .home__content {
    grid-template-columns: 220px repeat(2, 1fr);
    column-gap: 0.25rem;
    position: absolute;

    left: 24rem;

    top: 17rem;
    padding-top: 6rem;
  }

  .home__title {
    font-size: 1.2rem;
    right: 24rem;
    bottom: 2rem;
    width: 290px;
  }
  .home__img {
    width: 360px;
    height: 200px;
    margin-top: 3rem;
    margin-left: -14rem;
  }
  .btn__contact__home {
    width: 100%;
  }
  .home__title::before {
    height: 110%;

    top: -0.5rem;
  }
}

@media screen and (max-width: 800px) {
  .home__content {
    grid-template-columns: 220px repeat(2, 1fr);
    column-gap: 0.25rem;
    position: absolute;

    left: 21rem;

    top: 17rem;
    padding-top: 6rem;
  }
}

@media screen and (max-width: 834px) {
  .home__content {
    grid-template-columns: 220px repeat(2, 1fr);
    column-gap: 0.25rem;
    position: absolute;

    left: 21rem;

    top: 17rem;
    padding-top: 6rem;
  }
}
/*For medium devices*/

@media screen and (max-width: 768px) {
  .home__content {
    top: 10rem;
    left: 20rem;
  }
  .home__title {
    font-size: 1.2rem;
    right: 23rem;
    width: 280px;
    top: 2rem;
  }
  .home__img {
    width: 380px;
    height: 220px;
    margin-top: 7rem;
    margin-left: -14rem;
  }
  .btn__contact__home {
    width: 110%;
    padding: 10px;
  }
}
@media screen and (max-width: 580px) {
}
@media screen and (max-width: 578px) {
  .home__content {
    padding-top: 6rem;
    padding-bottom: 3rem;
  }
  .home__img {
    order: 2;
    width: 300px;
    height: 200px;
  }

  .home__data {
    grid-column: 1/3;
  }
}
@media screen and (max-width: 576px) {
  .home__content {
    grid-template-columns: 10px 1fr;
    padding-top: 2rem;
    left: 27rem;
    margin-bottom: -2rem;
  }
  .home__img {
    order: 2;
    width: 340px;
    height: 200px;

    margin-left: -20rem;
  }
  .home__title {
    font-size: 1rem;
    right: 22rem;
  }
}
@media screen and (max-width: 430px) {
  .home__content {
    grid-template-columns: 10px 1fr;
    top: 11rem;
    left: 20rem;
  }
  .home__img {
    order: 2;
    width: 340px;
    height: 200px;

    margin-left: -20rem;
  }
  .home__title {
    font-size: 1rem;
    right: 22rem;
  }
}
@media screen and (max-width: 414px) {
  .home__content {
    grid-template-columns: 10px 1fr;
    top: 12rem;
    left: 19.8rem;
  }
}
/*For small devices*/

@media screen and (max-width: 390px) {
  .home__content {
    top: 12rem;
    left: 19.2rem;
  }
}

@media screen and (max-width: 375px) {
  .home__content {
    grid-template-columns: 10px 1fr;
    top: 12rem;
    left: 19rem;
  }
  .home__img {
    order: 2;
    width: 310px;
    height: 200px;
    margin-top: 7rem;
    margin-left: -23rem;
  }
  .home__title {
    font-size: 1rem;
    right: 23.2rem;
  }

  .home__data {
    grid-column: 1/3;
    padding-right: 0;
  }
}

@media screen and (max-width: 360px) {
  .home__content {
    grid-template-columns: 10px 1fr;
    top: 12rem;
    left: 82%;
  }
}

@media screen and (max-width: 350px) {
  .home__content {
    grid-template-columns: 10px 1fr;
    top: 13rem;
    left: 90%;
    max-width: max-content;
  }
  .home__img {
    order: 2;
    width: 250px;
    height: 150px;
    margin-top: 7rem;
    margin-left: -25rem;
  }
  .home__title {
    right: 22.5rem;
  }

  .home__title::before {
    right: 22.5rem;
    height: 110%;
  }
  .home__data {
    grid-column: 1/3;
  }

  .btn__contact__home {
    width: 90%;
  }
}
